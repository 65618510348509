// @ts-strict-ignore
/**
 * FormBuilder generates forms based on the provided form definition Array.
 **/
import React, { forwardRef, useEffect, useState } from 'react';
import _ from 'lodash';
import { Form } from 'react-final-form';
import { CancelAndExecute } from '@/core/CancelAndExecute.molecule';
import { FormElement } from '@/formbuilder/formBuilder.constants';
import {
  decorateWithFieldNames,
  decorateWithNumbers,
  flattenFormDefinition,
  renderIt,
} from '@/formbuilder/formbuilder.utilities';
import { CancelAndSave } from '@/core/CancelAndSave.molecule';
import classNames from 'classnames';
import { ButtonVariant } from '@seeqdev/qomponents/dist/Button/Button.types';
import { useForwardRef } from '@/formbuilder/hooks/useForwardRef';

interface FormBuilderProps {
  testId?: string;
  // the formDefinition Array of FormElements defines the form to be rendered
  formDefinition: FormElement[];
  // the function that is called when the form is submitted
  submitFn: (any) => void | Promise<any>;
  // the function that is called when the cancel button is clicked
  closeFn: () => void;
  // id of the tool (legacy - may no longer apply)
  toolId?: string;
  // id of the submitBtnId (legacy - may no longer apply)
  submitBtnId?: string;
  // if debug is enabled then the form values and form errors are rendered below this form;
  debug?: boolean;
  // by default we assume we're creating a tool panel, however if we want to use a CancelAndSave as the button
  // component we can do so by setting this to true
  saveAndCancel?: boolean;
  // option to have the cancel button say something other than 'Cancel'
  cancelBtnLabel?: string;
  // option to have the submit button say something other than 'Execute'
  submitBtnLabel?: string;
  // option to have different Save or Execute submit button
  submitBtnVariant?: ButtonVariant;
  // option to hide the cancel button
  hideCancel?: boolean;
  // wrap the form in a panel
  wrapInPanel?: boolean;
  // extra classnames applied if not wrapped in panel
  extraClassNamesUnwrapped?: string;
  // option to hide the submit button
  hideSubmit?: boolean;
  // option to hide the execute button
  hideExecute?: boolean;
  // extra class-names for action buttons
  buttonExtraClassNames?: string;
  // this is useful for those rare cases where the submit button should remain in a loading state after the submitFn is
  // resolved (ex. oauth)
  continueProcessing?: boolean;
  // option to pass a ref to access submit button status
  ref?: any;
}

const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => e.preventDefault();

export const FormBuilder: React.FunctionComponent<FormBuilderProps> = forwardRef(function FormBuilder(props, ref) {
  const formRef = useForwardRef(ref);
  const {
    testId,
    formDefinition,
    submitFn,
    closeFn,
    toolId,
    cancelBtnLabel,
    submitBtnId,
    submitBtnLabel,
    submitBtnVariant,
    debug = false,
    saveAndCancel = false,
    hideCancel = false,
    wrapInPanel = true,
    extraClassNamesUnwrapped,
    hideSubmit,
    hideExecute = false,
    buttonExtraClassNames,
    continueProcessing,
  } = props;

  const [formData, setFormData] = useState([]);

  const flatFormDefinition: FormElement[] = flattenFormDefinition(formDefinition);
  const withFieldNames = decorateWithFieldNames(formDefinition);
  const withDisplayNumbers = decorateWithNumbers(withFieldNames);

  useEffect(() => {
    const formData = _.chain(flatFormDefinition).keyBy('name').mapValues('value').value();
    setFormData(formData as any);
  }, [formDefinition]);

  const renderToolPanelForm = (handleSubmit, enableSubmitBtn) => (
    <div className={wrapInPanel ? 'card card-primary pb10' : extraClassNamesUnwrapped}>
      {_.map(withDisplayNumbers, (def) => renderIt(def))}
      <div className={classNames(buttonExtraClassNames, { pb10: wrapInPanel })}>
        <CancelAndExecute
          cancelAction={closeFn}
          submitAction={() => Promise.resolve().then(handleSubmit)}
          toolId={toolId}
          btnDisabled={!enableSubmitBtn}
          cancelBtnLabel={cancelBtnLabel}
          submitBtnLabel={submitBtnLabel}
          hideCancel={hideCancel}
          submitBtnId={submitBtnId}
          hideExecute={hideExecute}
        />
      </div>
    </div>
  );

  const renderSaveAndCancelForm = (handleSubmit, enableSubmitBtn, values) => (
    <>
      {_.map(withDisplayNumbers, (def) => renderIt(def))}
      {!(hideCancel && hideSubmit) ? (
        <div className={classNames('pb10 mt30 flexColumnContainer flexCenter', buttonExtraClassNames)}>
          <CancelAndSave
            submitFn={() => Promise.resolve().then(handleSubmit)}
            cancelFn={closeFn}
            values={values}
            btnDisabled={!enableSubmitBtn}
            cancelBtnLabel={cancelBtnLabel}
            submitBtnLabel={submitBtnLabel}
            submitBtnVariant={submitBtnVariant}
            hideSubmit={hideSubmit}
            hideCancel={hideCancel}
            continueProcessing={continueProcessing}
          />
        </div>
      ) : null}
    </>
  );

  const renderForm = () => {
    return (
      <Form
        onSubmit={submitFn}
        initialValues={formData}
        render={({ handleSubmit, values, errors, submitFailed, modified, form }) => {
          /**
           * the submit button should be enabled if:
           *  - the form is pristine (no fields have been modified)
           *  - if the form has already been submitted once, then the button should only be enabled
           *    if there are no errors
           *  - if the form has not been submitted, and all modified fields are valid
           **/
          const modifiedFields = _.filter(_.keys(modified), (key) => (modified[key] ? key : null));
          const defaultProvidedFields = _.map(
            _.filter(flatFormDefinition, (item) => (item.defaultProvided ? item.name : null)),
            'name',
          );
          const modifiedFieldsWithErrors = _.filter(_.keys(errors), (error) =>
            _.includes(modifiedFields, error) ? error : null,
          );
          const defaultProvidedFieldsWithErrors = _.filter(_.keys(errors), (error) =>
            _.includes(defaultProvidedFields, error) ? error : null,
          );
          const enableSubmitBtn = submitFailed
            ? _.size(errors) < 1
            : _.size(modifiedFieldsWithErrors) < 1 && _.size(defaultProvidedFieldsWithErrors) < 1;

          if (formRef) {
            formRef.current = enableSubmitBtn;
          }

          return (
            <form
              onSubmit={handleFormSubmit}
              data-testid={testId || toolId}
              className={classNames({ simpleSaveForm: saveAndCancel })}>
              {!saveAndCancel && renderToolPanelForm(handleSubmit, enableSubmitBtn)}
              {saveAndCancel && renderSaveAndCancelForm(handleSubmit, enableSubmitBtn, values)}
              {debug && (
                <>
                  <pre>{JSON.stringify(values, null, 2)}</pre>
                  <pre>{JSON.stringify(errors, null, 2)}</pre>
                </>
              )}
            </form>
          );
        }}
      />
    );
  };

  return renderForm();
});
